<template>
  <div class="fan-analysis">
    <div class="analysis-top">
      <div class="analysis-left">
        <div class="gender-distribution">
          <span class="title">性别分布</span>
          <div class="distribution-chart">
            <div class="sex-name">
              <span class="name-span">{{ woman }}%</span>
              <span class="name-span">{{ man }}%</span>
            </div>
            <div class="chart-content">
              <div class="mab" :style="{ width: woman + '%' }">
                <div class="mab-color"></div>
              </div>
              <div class="woman">
                <div class="woman-color"></div>
              </div>
            </div>
            <div class="gender-icon">
              <div class="gender-icon-left">
                <i class="iconfont">&#xe6a8;</i>
                <span>女性用户</span>
              </div>
              <div class="gender-icon-right">
                <i class="iconfont">&#xe6a6;</i>
                <span>男性用户</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="analysis-right">
        <div class="age-distribution">
          <span class="title">年龄分布</span>
          <div
            id="ageDistribution"
            style="width: 100%; height: 275px; position: relative"
          ></div>
        </div>
      </div>
    </div>
    <div class="analysis-bottom">
      <span class="top-title" style="margin-top: 10px">省市排名Top10</span>
      <div class="title-content">
        <span
          :class="['title', radio === 0 ? 'title-active' : '']"
          @click="changeTitleValue"
          >省份分布</span
        >
        <span class="title" style="margin: 0 13px">|</span>
        <span
          :class="['title', radio === 1 ? 'title-active' : '']"
          @click="changeTitleValue"
          >城市分布</span
        >
        <div class="two-title"></div>
        <span>名称</span>
        <span>占比</span>
      </div>
      <div class="area-distribution" v-if="radio == 0 || radio == 1">
        <div class="area-item-box" v-if="areaData.length > 0">
          <div class="area-item" v-for="(item, index) in areaData" :key="index">
            <span class="area-name">{{ item.areaName }}</span>
            <div class="area-progress-box">
              <div
                class="area-progress-num"
                :style="{ width: item.percentage + '%' }"
              ></div>
            </div>
            <span class="percentage text-overflow">{{ item.percent }}%</span>
          </div>
        </div>
        <div v-else class="null-data-area">暂无数据</div>
      </div>
      <div style="margin-top: 50px">商品购买需求分布</div>
      <!-- 图表 -->
      <div class="goodsEcharts-main">
        <!-- 左边图表 -->
        <div class="main-left">
          <!-- 第一个图表 -->
          <div class="top-echarts">
            <span
              class="iconfont"
              style="color: orange; font-size: 20px; margin-right: 75px"
              >&#xe654;<i class="top-font">Top1</i></span
            >
            <span
              style="font-size: 15px; z-index: 10"
              v-for="(item, index) in productData[0]"
              :key="index"
              @click="productItemClick(productData[0])"
              >{{ item.title }}{{ item.rate }}</span
            >
            <span>%</span>
            <div class="top-echart-main">
              <div id="top1-echarts-content" class="echart-main-size"></div>
              <div class="echarts-content-text">
                <div class="content-title">
                  <span
                    style="margin-top: 15px; z-index: 10"
                    @click="productItemClick(tItem)"
                    v-for="(tItem, tIndex) in productData[0] &&
                    productData[0].sub_purpose_product_item"
                    :key="tIndex"
                    >{{ tItem.category.title }}</span
                  >
                </div>
                <div class="content-desc">
                  <span
                    style="margin-top: 15px"
                    @click="productItemClick(tItem)"
                    v-for="(rItem, rIndex) in productData[0] &&
                    productData[0].sub_purpose_product_item"
                    :key="rIndex"
                    >{{ rItem.category.rate }}%</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 第二个图表 -->
          <div class="top-echarts">
            <span
              class="iconfont"
              style="color: orange; font-size: 20px; margin-right: 75px"
              >&#xe654;<i class="top-font">Top2</i></span
            >
            <span
              style="font-size: 15px"
              v-for="(item, index) in productData[1]"
              :key="index"
              @click="productItemClick(productData[1])"
              >{{ item.title }}{{ item.rate }}</span
            >
            <span>%</span>
            <div class="top-echart-main">
              <div id="top2-echarts-content" class="echart-main-size"></div>
              <div class="echarts-content-text">
                <div class="content-title">
                  <span
                    style="margin-top: 15px"
                    v-for="(tItem, tIndex) in productData[1] &&
                    productData[1].sub_purpose_product_item"
                    :key="tIndex"
                    @click="productItemClick(productData[1])"
                    >{{ tItem.category.title }}</span
                  >
                </div>
                <div class="content-desc">
                  <span
                    style="margin-top: 15px"
                    v-for="(rItem, rIndex) in productData[1] &&
                    productData[1].sub_purpose_product_item"
                    :key="rIndex"
                    @click="productItemClick(productData[1])"
                    >{{ rItem.category.rate }}%</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 第三个图表 -->
          <div class="top-echarts">
            <span
              class="iconfont"
              style="color: orange; font-size: 20px; margin-right: 75px"
              >&#xe654;<i class="top-font">Top3</i></span
            >
            <span
              style="font-size: 15px"
              v-for="(item, index) in productData[2]"
              :key="index"
              @click="productItemClick(productData[2])"
              >{{ item.title }}{{ item.rate }}</span
            >
            <span>%</span>
            <div class="top-echart-main">
              <div id="top3-echarts-content" class="echart-main-size"></div>
              <div class="echarts-content-text">
                <div class="content-title">
                  <span
                    style="margin-top: 15px"
                    v-for="(tItem, index) in productData[2] &&
                    productData[2].sub_purpose_product_item"
                    @click="productItemClick(productData[2])"
                    :key="index"
                    >{{ tItem.category.title }}</span
                  >
                </div>
                <div class="content-desc">
                  <span
                    style="margin-top: 15px"
                    v-for="(rItem, index) in productData[2] &&
                    productData[2].sub_purpose_product_item"
                    @click="productItemClick(productData[2])"
                    :key="index"
                    >{{ rItem.category.rate }}%</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边图表 -->
        <div class="main-right">
          <div id="price-echarts" style="height: 350px; width: 350px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showFansData } from "@/utils/apis";
export default {
  props: ["author_id"],
  data() {
    return {
      radio: 0,
      tableData: [],
      genderData: {},
      areaData: [],
      productData: [],
      man: 0,
      woman: 0,
      ageDistribution: null,
      topOne: null,
      topTwo: null,
      topThree: null,
      priceRange: null,
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    productItemClick(e) {
      console.log(e);
    },
    //   根据省会或者城市显示数据
    changeTitleValue() {
      if (this.radio == 0) {
        this.radio = 1;
      } else {
        this.radio = 0;
      }
      this.getListByCh();
    },
    getListByCh() {
      if (this.radio == 0) {
        this.areaData = [];
        this.areaDataFun(this.tableData.province);
      } else {
        this.areaData = [];
        this.areaDataFun(this.tableData.city);
      }
    },
    // 设置粉丝分布数据
    getVideoDetail() {
      showFansData(this.author_id)
        .then((res) => {
          this.tableData = res.data;
          console.log(res.data.purpose_list.shift()); //所有全部
          this.productData = res.data.purpose_list;
          console.log(this.productData);
          //  设置省市百分百
          if (this.tableData.province.length) {
            this.areaDataFun(this.tableData.province);
          }
          this.man = this.tableData.gender[0].rate; //男性比例
          this.woman = this.tableData.gender[1].rate; //女性比例
          this.ageDistributionFun(this.tableData.age); //设置年龄

          this.topOneEcharts();
          this.topTwoEcharts();
          this.topThreeEcharts();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    areaDataFun(arry) {
      //地区数据处理
      let sum = arry[0].rate;
      let sum1 = 0;
      for (let i in arry) {
        sum1 += arry[i].rate;
      }
      for (let i in arry) {
        let obj = {
          areaName: arry[i].title, //省市名称
          percentage: Math.round((arry[i].rate / sum) * 10000) / 100.0, //进度条
          percent: Math.round((arry[i].rate / sum1) * 10000) / 100.0, //占比
        };
        this.areaData.push(obj);
      }
    },
    ageDistributionFun(array) {
      //年龄段数据处理
      let ageArray = [],
        dataArray = [];
      for (let i in array) {
        var str = Number(array[i].rate).toFixed(2);
        ageArray.push(array[i].title);
        let obj = {
          value: str,
          name: array[i].title,
        };
        dataArray.push(obj);
      }
      this.ageDistribution = this.$echarts.init(
        document.getElementById("ageDistribution")
      );
      this.ageDistribution.setOption({
        tooltip: {
          //提示框组件
          trigger: "item", //触发类型(饼状图片就是用这个)
          formatter: function (params) {
            return (
              params.seriesName +
              "<br/>" +
              params.name +
              "岁: " +
              params.value +
              "%"
            );
          },
        },
        color: [
          "#9ECB59",
          "#5F55F1",
          "#C3C3F4",
          "#F5697B",
          "#F5AF23",
          "#0097FB",
        ],
        legend: {
          padding: [0, 60, 0, 0],
          orient: "horizontal", //布局  纵向布局 图例标记居文字的左边 vertical则反之
          width: 40, //图行例组件的宽度,默认自适应
          x: "right", //图例显示在右边
          y: "bottom", //图例在垂直方向上面显示居中
          itemWidth: 10, //图例标记的图形宽度
          itemHeight: 10, //图例标记的图形高度
          data: ageArray,
          textStyle: {
            color: "#000",
            fontSize: 14,
            lineHeight: 22,
          },
          formatter: function (params) {
            return params + "岁";
          },
        },
        series: [
          {
            name: "年龄分布",
            type: "pie",
            data: dataArray,
            center: ["40%", "58%"],
            radius: ["60%", "70%"],
            itemStyle: {
              normal: {
                label: {
                  //饼图图形上的文本标签
                  show: true, //平常不显示
                  color: "#000",
                  fontSize: 18,
                  formatter: function (params) {
                    return params.value + "%";
                  },
                },
                labelLine: {
                  //标签的视觉引导线样式
                  show: true, //平常不显示
                },
              },
              emphasis: {
                label: {
                  show: true,
                  position: "center",
                  textStyle: {
                    fontSize: "10",
                    fontWeight: "bold",
                  },
                },
              },
            },
          },
        ],
      });
    },
    // 设置商品排行左侧图表1
    topOneEcharts() {
      var datas = {
        title: this.tableData.purpose_list[0].category.title,
        value: this.tableData.purpose_list[0].category.rate,
        company: "%",
        ringColor: [
          {
            offset: 0,
            color: "rgb(82,196,26)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgb(82,196,26)", // 100% 处的颜色
          },
        ],
      };
      this.topOne = this.$echarts.init(
        document.getElementById("top1-echarts-content")
      );
      this.topOne.setOption({
        title: {
          text: datas.value + "%" + "\n" + datas.title,
          x: "center",
          y: "center",
          textStyle: {
            fontWeight: "normal",
            color: "#666",
            fontSize: "15",
          },
        },
        color: ["#eee"],
        legend: {
          show: false,
        },

        series: [
          {
            type: "pie",
            clockWise: true,
            radius: ["40%", "30%"],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: datas.value,
                name: "",
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: datas.ringColor,
                    },
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: "",
                value: 100 - datas.value,
              },
            ],
          },
        ],
      });
    },
    // 设置商品排行左侧图表2
    topTwoEcharts() {
      var datas = {
        title: this.tableData.purpose_list[1].category.title,
        value: this.tableData.purpose_list[1].category.rate,
        company: "%",
        ringColor: [
          {
            offset: 0,
            color: "rgb(82,196,26)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgb(82,196,26)", // 100% 处的颜色
          },
        ],
      };
      this.topTwo = this.$echarts.init(
        document.getElementById("top2-echarts-content")
      );
      this.topTwo.setOption({
        title: {
          text: datas.value + "%" + "\n" + datas.title,
          x: "center",
          y: "center",
          textStyle: {
            fontWeight: "normal",
            color: "#666",
            fontSize: "15",
          },
        },
        color: ["#eee"],
        legend: {
          show: false,
        },

        series: [
          {
            type: "pie",
            clockWise: true,
            radius: ["40%", "30%"],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: datas.value,
                name: "",
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: datas.ringColor,
                    },
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: "",
                value: 100 - datas.value,
              },
            ],
          },
        ],
      });
    },
    // 设置商品排行左侧图表3
    topThreeEcharts() {
      var datas = {
        title: this.tableData.purpose_list[2].category.title,
        value: this.tableData.purpose_list[2].category.rate,
        company: "%",
        ringColor: [
          {
            offset: 0,
            color: "rgb(82,196,26)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgb(82,196,26)", // 100% 处的颜色
          },
        ],
      };
      this.topThree = this.$echarts.init(
        document.getElementById("top3-echarts-content")
      );
      this.topThree.setOption({
        title: {
          text: datas.value + "%" + "\n" + datas.title,
          x: "center",
          y: "center",
          textStyle: {
            fontWeight: "normal",
            color: "#666",
            fontSize: "15",
          },
        },
        color: ["#eee"],
        legend: {
          show: false,
        },

        series: [
          {
            type: "pie",
            clockWise: true,
            radius: ["40%", "30%"],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: datas.value,
                name: "",
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: datas.ringColor,
                    },
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: "",
                value: 100 - datas.value,
              },
            ],
          },
        ],
      });
    },
    // 设置价格占比图表
    priceEcharts() {
      var colorList = [
        "#73DDFF",
        "#73ACFF",
        "#FDD56A",
        "#FDB36A",
        "#FD866A",
        "#9E87FF",
        "#58D5FF",
      ];
      var arr = [
        { value: 123, name: "丙烯腈" },
        { value: 102, name: "环氧乙烷" },
        { value: 122, name: "非甲烷总烃" },
        { value: 137, name: "氯乙烯" },
        { value: 109, name: "氯乙烷" },
        { value: 111, name: "甲苯" },
      ];
       this.priceRange = this.$echarts.init(
        document.getElementById("price-echarts")
      );
      this.priceRange.setOption({
        color: colorList,
        title: {
          x: "center",
          y: "center",
          textStyle: {
            color: "#fff",
            fontSize: 15,
          },
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["24%", "35%"],
            clockwise: true,
            avoidLabelOverlap: true,
            hoverOffset: 15,
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "outside",
              formatter: "{a|{b}：{d}%}\n{hr|}",
              rich: {
                hr: {
                  backgroundColor: "t",
                  borderRadius: 3,
                  width: 3,
                  height: 3,
                  padding: [3, 3, 0, -12],
                },
                a: {
                  padding: [-30, 15, -20, 15],
                },
              },
            },
            labelLine: {
              normal: {
                length: 20,
                length2: 30,
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: arr,
          },
        ],
      })
    },
    // 初始化
    init(){
      this.getVideoDetail();
    this.ageDistribution = this.$echarts.init(
      document.getElementById("ageDistribution")
    );
    this.topOne = this.$echarts.init(
      document.getElementById("top1-echarts-content")
    );
    this.topTwo = this.$echarts.init(
      document.getElementById("top2-echarts-content")
    );
    this.topThree = this.$echarts.init(
      document.getElementById("top3-echarts-content")
    );
    this.priceRange = this.$echarts.init(
      document.getElementById("price-echarts")
    );
    let resizeTimer = null;
    const self = this;
    window.addEventListener("resize", function () {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        self.ageDistribution.resize();
        self.topOne.resize();
        self.topTwo.resize();
        self.topThree.resize();
        self.priceRange.resize();
      }, 100);
    });
    }
  },
};
</script>

<style scoped lang="scss">
.fan-analysis {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px;
  .analysis-top {
    display: flex;
    padding-bottom: 34px;
    margin: 0 30px;
    .analysis-left {
      display: flex;
      flex-direction: column;
      width: 50%;
      border-right: 5px solid #eeeeee;
      padding: 0 54px 0 24px;
      .gender-distribution {
        display: flex;
        flex-direction: column;
        height: 100%;
        .title {
          font-size: 14px;
          font-weight: 400;
          color: #333;
        }
        .distribution-chart {
          display: flex;
          flex-direction: column;
          flex: 1;
          height: 1%;
          justify-content: center;
          .sex-name {
            display: flex;
            justify-content: space-between;
            .name-span {
              color: #000000;
              font-size: 18px;
              line-height: 1;
            }
          }
          .chart-content {
            width: 100%;
            display: flex;
            .mab {
              height: 100%;
              display: flex;
              flex-direction: column;
              text-align: center;
              .mab-color {
                background: #ff6a6a;
                border-radius: 7px;
                height: 12px;
                margin-top: 50px;
              }
            }
            .woman {
              width: 1%;
              flex: 1;
              display: flex;
              flex-direction: column;
              text-align: center;
              .woman-color {
                width: 100%;
                height: 12px;
                background: #564ced;
                border-radius: 7px;
                margin-top: 50px;
              }
            }
          }
          .gender-icon {
            display: flex;
            justify-content: space-between;
            margin-top: 54px;
            .gender-icon-left,
            .gender-icon-right {
              display: flex;
              flex-direction: column;
              text-align: center;
              &.gender-icon-left {
                i {
                  color: #ff6a6a;
                }
              }
              &.gender-icon-right {
                i {
                  color: #564ced;
                }
              }
              i {
                font-size: 40px;
              }
              span {
                font-size: 14px;
                color: #444444;
                line-height: 1;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
    .analysis-right {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
      .age-distribution {
        padding-bottom: 20px;
        .title {
          padding-left: 32px;
          font-size: 14px;
          font-weight: 400;
          color: #333;
        }
      }
    }
  }
  .analysis-bottom {
    display: flex;
    flex-direction: column;
    padding: 0 34px;
    .top-title {
      font-size: 16px;
      font-weight: bold;
    }
    .title-content {
      margin-top: 30px;
      .title-active {
        color: rgb(0, 0, 255);
      }
    }
    .title {
      line-height: 1;
      cursor: pointer;
    }
    .two-title {
      line-height: 1;
      display: flex;
      justify-content: space-between;
      padding: 0 14px 0 26px;
      color: #666666;
      font-size: 14px;
      margin-top: 50px;
    }
    .area-distribution {
      padding: 0 14px 0 26px;
      display: flex;
      flex-direction: column;
      .area-item-box {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        .area-item {
          display: flex;
          align-items: center;
          line-height: 1;
          margin-top: 40px;
          span {
            color: #666666;
            font-size: 14px;
            &.area-name {
              text-align: left;
              width: 50px;
            }
            &.percentage {
              width: 60px;
              text-align: right;
            }
          }
          .area-progress-box {
            display: flex;
            flex: 1;
            width: 1%;
            .area-progress-num {
              height: 15px;
              background: linear-gradient(45deg, #ffa72a 0%, #f8b95f 98%);
              border-radius: 7px;
            }
          }
        }
      }
    }
    .goodsEcharts-main {
      margin-top: 30px;
      height: auto;
      width: 100%;
      display: flex;
      .main-left {
        padding-top: 30px;
        width: 40%;
        display: flex;
        flex-direction: column;
        .top-echarts {
          align-items: center;
          font-weight: bold;
          .top-font {
            color: rgb(0, 0, 0);
            font-size: 16px;
          }
          .top-echart-main {
            width: auto;
            display: flex;
            flex: 1;
            .echart-main-size {
              width: 220px;
              height: 220px;
            }
            .echarts-content-text {
              display: flex;
              align-items: center;
              .content-title {
                display: flex;
                flex-direction: column;
              }
              .content-desc {
                margin-left: 100px;
                display: flex;
                font-size: 16px;
                flex-direction: column;
              }
            }
          }
        }
      }
      .main-right {
        width: 60%;
        height: auto;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
  }
  .null-data-area {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
}
</style>