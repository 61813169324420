<template>
    <div v-if="tableData.length == 0">
        <div class="NoDataimg">
            <!--<p><img src="../../../../assets/images/icon/wushuju.png" /></p>-->
            <p style="text-align: center">暂无数据</p>
        </div>
    </div>
    <div class="ecommercedata" v-else-if="tableData.length > 0">
        <el-table :data="tableData" style="width: 100%;flex: 1"size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="goods" label="商品">
                <template slot-scope="scope">
                    <div class="goods-name">
                        <img class="goods-img" :src="scope.row.imgUrl">
                        <el-popover trigger="hover" placement="top">
                            <span class="hover-goods-name">{{scope.row.name}}</span>
                            <div slot="reference" style="margin-left: 10px;">
                                <span style="margin-left: 10px" @click="openShop(scope.row.url)">{{scope.row.name}}</span>
                                <!--<img class="goods-icon-img" :src="scope.row.url">-->
                            </div>
                        </el-popover>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="up" label="视频点赞总量" sortable width="150" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.up}}W</span>
                </template>
            </el-table-column>
            <el-table-column prop="view" label="浏览量总量" sortable width="150" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.view}}W</span>
                </template>
            </el-table-column>
            <el-table-column prop="sale" label="全网销量总量" sortable width="150" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.sale}}W</span>
                </template>
            </el-table-column>
            <el-table-column prop="price" label="售价" sortable width="120" style="color: #FF0000;" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.price}}W</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="120" align="center">
                <template slot-scope="scope">
                     <span slot="reference" class="handel handel-goods">
						<i class="iconfont">&#xe683;</i>
                     </span>
                    <span slot="reference" class="handel handel-link"><i class="iconfont">&#xe60c;</i></span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="studentPages.currentPageNum"
                :page-size="studentPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="studentPages.total"
                @current-change="studentCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "EcommerceDataModule",
        data() {
            return {
                tableData: [
                    {
                        imgUrl:require('../../assets/img/ceshi/3.jpg'),
                        name:'网红糖果球灯花束diy材料套餐 糖果色星空灯 圣诞节浪漫球灯花束',
                        up:1.4,
                        view:1.4,
                        sale:1.4,
                        price:1.4,
                    },
                ],

                studentPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        methods:{
            //分页
            studentCurrentChange(val) {
                this.studentPages.currentPageNum = val;
                this.getStudentList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .ecommercedata{
        height: 100%;
        padding: 0 20px;
        .goods-name {
            display: flex;
            justify-content: flex-start;
            .goods-img {
                width: 80px;
                height: 80px;
            }
        }
        .handel-goods {
            display: inline-block;
            width: 28px;
            height: 28px;
            background: rgba(255, 7, 7, 1);
            border-radius: 50%;
            line-height: 28px;
        }
        .handel-link {
            display: inline-block;
            width: 28px;
            height: 28px;
            background: #42D7AB;
            border-radius: 50%;
            line-height: 28px;
            margin-left: 10px;
        }
        .handel {
            cursor: pointer;
            i {
                color: #fff;
            }
        }
        .hover-goods-name {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            margin-bottom: 34px;
        }
        ::v-deep.el-table__body-wrapper{
            flex: 1;
            height: calc(100% - 60px);
            overflow: auto;
        }
        ::v-deep.el-table{
            height: calc(100% - 60px);
        }
        ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
            background-color: transparent;
        }


    }


</style>