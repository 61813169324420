<template>
    <div class="company-contain">
        <div class="table-box">
            <el-table
                    :data="taskList"
                    class="college-table"
                    style="width: 100%"
                    size="medium"
                    :header-cell-style="{fontWeight: 'Regular', color: '#333', background: '#fff',}"
                    :cell-style="{fontSize: '12px',color: '#333'}"
            >
                <el-table-column
                        prop="aweme_title"
                        label="视频作品"
                        align="left"
                        width="700"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <div @click="changeToDetail(scope.row)" class="videoBox"
                             style="display: flex;cursor: pointer;">
                            <div class="picBox">
                                <img style="width: 90px;height: 120px;" :src=scope.row.aweme_cover>
                            </div>
                            <div style="padding: 20px 0 10px 20px;">
                                <div style="font-size:14px;">{{scope.row.aweme_title}}</div>
                                <div>
                                    <span>热词：</span>
                                    <span v-if="scope.row.hot_words == ''">无</span>
                                    <span v-else style="margin-left: 5px;"
                                          v-for="item in (this.videoList.hot_words || '').split(',')" :key="item">{{item}}</span>
                                </div>
                                <div>
                                    <span>发布时间：</span>
                                    <span>{{scope.row.aweme_create_time}}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="digg_count" label="点赞量" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.digg_count}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="comment_count" label="评论数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.comment_count}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="share_count" label="转发" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.share_count}}</span>
                    </template>
                </el-table-column>
                <!--                <el-table-column prop="video_total_forwarding" label="涨粉" align="center" class-name="student-name">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <span class="student-name-title">{{scope.row.video_total_forwarding}}</span>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
                <el-table-column label="操作" width="250" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" circle @click="changeToDetail(scope.row)" size="small">
                            <i class="iconfont">&#xe6a1;</i>
                        </el-button>
                        <el-button type="success" circle size="small" @click="jumpToFansData(scope.row)">
                            <i class="iconfont">&#xe647;</i>
                        </el-button>
                        <el-button type="danger" circle @click="jumpToVideo(scope.row)" size="small">
                            <i class="iconfont">&#xe63a;</i>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
                class="pages-center"
                style="margin-bottom: 10px;"
                :current-page="adminPages.currentPageNum"
                :page-size="adminPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="adminPages.total"
                @current-change="adminCurrentChange"
        ></el-pagination>
    </div>
</template>

<script>
    export default {
        props: ["author_id"],
        name: 'HotVideo',
        data() {
            return {
                isAll: 0,
                radio: "0",
                video_class_name: "",//分类名称
                typeLsit: [], //分类列表
                task_condition: "",
                taskList: [],//任务列表
                //分页
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //编辑获得的数据
                editForm: {
                    id: '',
                    tasktype: "",//任务分类
                    title: "", //标题
                    describe: "", //描述
                    cover: "", //封面
                    time: "", //截至时间
                    Islimit: "",//是否限制人数 0否 1是
                    limitnum: "",//限制人数
                    taskmoney: "",//任务金额
                    apply: "",//是否报名 0否 1是
                    file: [] //附件
                }
            }
        },
        mounted() {
            this.getAllClass();
        },
        methods: {
            get1() {

            },
            // 获取全部视频列表
            getAllClass() {
                let param = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum, //页面显示条数
                    author_id: this.author_id,
                };
                this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoList, param, res => {
                    if (res.code == 200) {
                        this.taskList = res.data.data;
                        this.adminPages.total = res.data.total;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                });
            },
            //分页
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getAllClass();
            },
            //跳到详情页
            changeToDetail(row) {
                this.$router.push({
                    path: "/student/hot/authorVideoDetail",
                    query: {
                        aweme_id: row.aweme_id,
                        activename: "first",
                    }
                })
            },
            jumpToFansData(row) {
                this.$router.push({
                    path: "/student/hot/authorVideoDetail",
                    query: {
                        aweme_id: row.aweme_id,
                        activename: "second",
                    }
                })
            },
            //打开视频
            jumpToVideo(row) {
                window.open(row.aweme_url);
            },
        },
        watch: {
            isAll: function (val) {
                this.getAllClass();
            },
        }

    }
</script>

<style scoped lang="scss">
    .company-contain {
        background-color: #ffffff;
        height: 100%;

        .head_box {
            height: 170px;
        }

        .divide {
            padding: 10px 20px 0 20px;
        }

        .filter-box {
            padding-top: 10px;

            .box1 {
                padding: 0 20px 10px 20px;
                height: 40px;
                display: flex;

                .filter-box1 {
                    span {
                        margin-top: 6px;
                        line-height: 32px;
                        display: inline-block;
                        width: 66px;
                        height: 32px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }
        }

    }
</style>
