<template>
  <div class="favorite">
    <el-tabs v-model="ShortTab" type="card" @tab-click="handleClick">
      <el-tab-pane label="数据概况" name="first">
        <dataDetail v-if="this.ShortTab == 'first'" :author_id="author_id">
        </dataDetail>
      </el-tab-pane>
      <el-tab-pane label="粉丝数据分析" name="second">
        <fansData v-if="this.ShortTab == 'second'" :author_id="author_id">
        </fansData>
      </el-tab-pane>
      <el-tab-pane label="播主视频" name="third">
        <authorVideo v-if="this.ShortTab == 'third'" :author_id="author_id">
        </authorVideo>
      </el-tab-pane>
      <!--                    <el-tab-pane label="电商数据分析" name="fourth">-->
      <!--                        <authorEData v-if="this.ShortTab == 'fourth'" :author_id="author_id">-->
      <!--                        </authorEData>-->
      <!--                    </el-tab-pane>-->
    </el-tabs>
    <!-- 博主介绍 -->
    <EcMineModule :author_id="author_id"></EcMineModule>
  </div>
</template>

<script>
import dataDetail from "../../../components/mediumModule/DataOverviewModule";
import fansData from "../../../components/mediumModule/fansData";
import authorVideo from "../../../components/mediumModule/authorVideo";
import authorEData from "../../../components/mediumModule/authorEData";
import EcMineModule from "../../../components/mediumModule/EcMineModule";

export default {
  name: "VideoDetail",
  components: {
    dataDetail,
    fansData,
    authorVideo,
    authorEData,
    EcMineModule,
  },
  data() {
    return {
      ShortTab: this.$route.query.activename || "first",
      videoList: {}, //视频详情
      author_id: "",
      activename: "",
    };
  },
  created() {
    this.getVideoList();
  },
  methods: {
    //打开视频
    jumpToVideo() {
      window.open(this.videoList.video_url);
    },
    getVideoList() {
      this.author_id = this.$route.query.author_id;
    },
    handleClick(tab, event) {},
  },
};
</script>

<style scoped lang="scss">
.favorite {
  height: 100%;
  display: flex;

  ::v-deep .el-tabs {
    height: 100%;
    box-shadow: none;
    border: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    background: transparent;
    flex: 1;
    width: 1%;
  }

  ::v-deep .el-tabs__header {
    border-bottom: none;
    margin: 0;
  }

  ::v-deep .el-tabs__content {
    flex: 1;
    background: #fff;
    padding-top: 20px;
    min-height: 766px;
  }

  ::v-deep .el-tab-pane {
    height: 100%;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    background: #fff;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
    border-left: none;
  }

  ::v-deep .el-tabs__header .is-active {
    background: #fff;
    font-size: 16px;
    font-weight: 400;
    color: #0824ce;
    border-radius: 6px 6px 0 0;
  }

  ::v-deep .el-tabs__item {
    height: 52px;
    line-height: 52px;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
  }

  ::v-deep .el-tabs__item:hover {
    color: #0824ce;
    cursor: pointer;
  }
}
</style>